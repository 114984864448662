import React, { Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline"

export default function Lightbox({
  open,
  setOpen,
  currentItem,
  type,
}) {
  const cancelButtonRef = useRef(null)

  const [showMore, setShowMore] = useState(false);
  return (
    currentItem && (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => setOpen(false, currentItem)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-90 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <button
              className="fixed top-0 right-0 text-white rounded-lg"
              onClick={() => setOpen(false, currentItem)}
              style={{
                top: 10,
                right: 10,
                zIndex: 1
              }}
            >
            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="40" height="25.81" viewBox="0 0 40 26" style={{ position: 'relative', left: '0px', top: '-10px'}}>
                <path d="M40,.14c-1.42,9.76-9.82,17.26-19.98,17.26H0" style={{ fill: 'none', stroke: '#fff', strokeMiterlimit: 10, strokeWidth: 1}}/>
              </svg>
            </button>
            <div className=" min-h-full items-center justify-center text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-right shadow-xl transition-all mx-auto">
                  <div className="grid grid-cols-8" style={{marginTop: 100}} >
                    <div className="main-lightbox-slide-container md:col-span-6 sm:col-span-8 col-span-8 flex flex-col justify-end w-full" >
                   
                      {(currentItem.vimeolink !== '') && (
                        <iframe src={currentItem.vimeolink} width="auto" height="1920" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                      )}
                      {(currentItem.vimeolink === '') && (
                        <img
                          className="img-mobile-lightbox"
                          src={currentItem.thumbnail?.url}
                        ></img>
                      )}
                      {/* <iframe src="https://player.vimeo.com/video/756751519?h=bc3b1eb452&color=ffffff&title=0&byline=0&portrait=0" width="auto" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
                    </div>

                    {(type === 'ads') && (
                      <div className="ads-lightbox-container md:col-span-2 sm:col-span-8 col-span-8 flex flex-col justify-end text-start px-10">
                        <p className="lightbox-text-1 uppercase tracking-widest max-w-lg text-xl text-white my-4">
                          {currentItem.name}
                        </p>
                        <p className="lightbox-text-2 uppercase tracking-widest text-sm text-white mt-4">
                          {currentItem.director}
                        </p>
                        <p className="lightbox-text-3 tracking-widest text-sm text-white mb-4 mt-2">
                          Director
                        </p>
                      </div>
                    )}

                    {(type === 'films') && (
                      <div className="films-lightbox-container md:col-span-2 sm:col-span-8 col-span-8 flex flex-col justify-end text-start px-10">
                        {currentItem.development && (
                          <div className="lightbox-pill">
                            <p className="lightbox-films-title uppercase ">
                              In Development
                            </p>
                          </div>
                        )}
                  
                        <p className="lightbox-films-title uppercase tracking-widest text-sm text-white mb-0 mt-6">
                          Title
                        </p>
                        <p className="lightbox-films-title-text uppercase tracking-widest max-w-lg text-xl text-white mb-4">
                          {currentItem.name}
                        </p>
                        { currentItem.director !== '' &&(
                        <p className="lightbox-films-title uppercase tracking-widest text-sm text-white mb-0 mt-2">
                          Director
                        </p>
                        )}
                        
                        <p className="lightbox-films-director-text uppercase tracking-widest text-sm text-white">
                          {currentItem.director}
                        </p>
                        
                        <div className="lightbox-films-description tracking-widest max-w-sm text-sm text-white mt-4"
                          style={{maxHeight: 300, overflow: 'auto'}}
                          dangerouslySetInnerHTML={{ __html:  (showMore || currentItem.description.en_EN.length < 200) ? currentItem.description.en_EN : currentItem.description.en_EN.substring(0, 200) + "..." }}
                        />
                        <p className="tracking-widest max-w-sm text-sm text-white">
                        { (currentItem.description.en_EN.length >= 200) &&
                        <button style={{fontFamily: 'Catamaran-ExtraBold'}} onClick={() => setShowMore(!showMore)}>
                          <br/>{ showMore ? `Show Less` : `Show More` }
                        </button>
                        }
                        </p>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  )
}
